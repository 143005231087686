import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // modify the import statement
import { Navigation } from "./components/Navigation";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Menu } from "./components/Menu";
import { Contact } from "./components/Contact";
import { Special } from "./components/Special";

function App() {
  return (
    
    <Router>
      <Navigation />
      <div className="App">
        <Routes> {/* wrap the Route component in a Routes component */}
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/special" element={<Special />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;