import React from "react";
import { useState } from "react";
import "./Navigation.css";
import { Link } from "react-router-dom";


export function Navigation() {
  const [showLinkButtons, setShowLinkButtons] = useState(false);
  function handleMoreButtonClick() {
    setShowLinkButtons(!showLinkButtons);
    const moreButton = document.querySelector(".more-btn");
    if (showLinkButtons) {
      moreButton.textContent = "More";
    } else {
      moreButton.textContent = "Less";

    }
  }

  return (
    // TODO USE CHECK BUTTON FOR MENU EFFECT
    <div className="nav">
      
      <img className="logo" src="/SoboEnclaveLogo.PNG" alt="Sobo Logo" />
      <div className="links-divider">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/menu"> Menu</Link>
          </li>
          <li>
            <Link to="/special">Special</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <button className="more-btn" onClick={handleMoreButtonClick}>
              More
            </button>
          </li>
        </ul>

        {/* link buttons section */}

        {showLinkButtons && (
          <div className="link-buttons">
            <button className="order-online">
              < Link to="/menu" onClick={handleMoreButtonClick}>Order Online</Link>
            </button>
            <button className="promotion">
              <Link to="/special" onClick={handleMoreButtonClick} >Promotion</Link>
            </button>
            <button className="catering-service">
              <a href="/catering">Catering</a>
            </button>
            <button className="Testimonials">
              <a href="/testimonials">Testimonials</a>
            </button>
            <button className="faqs">
              <a href="/faqs">FAQs</a>
            </button>
            <button className="faqs">
              <a href="/faqs">Allergies</a>
            </button>

          </div>
        )}
      </div>
    </div>
  );
}