import React from "react";
import "./Carousel.css";

export function Carousel(props) {
  const [activeIndex, setActiveIndex] = React.useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function nextSlide() {
    setActiveIndex((activeIndex + 1) % props.slides.length);
  }

  function prevSlide() {
    setActiveIndex((activeIndex - 1 + props.slides.length) % props.slides.length);
  }

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [nextSlide]);
  
  return (
    <div className="carousel">
      <div className="carousel-slides">
        {props.slides.map((slide, index) => (
          <div
            key={index}
            className={`carousel-slide ${index === activeIndex ? "active" : ""}`}
          >
            <img src={slide} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <button className="carousel-prev" onClick={prevSlide}>
        &lt;
      </button>
      <button className="carousel-next" onClick={nextSlide}>
        &gt;
      </button>
    </div>
  );
}