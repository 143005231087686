import React from "react";
import "./Hero.css";

export function Hero() {
  return (
    <div className="hero">
      <div className="hero-content">{/* bg image here */}</div>
      <img src="../../public/images/hero.jpg" alt="hero" />

      <div className="ul-wrapper">
        <ul className="food-list">
          <h1> Indulge in our special Menu </h1>

          <li>
            <a href="/order">Banga Soup</a>
          </li>
          <li>
            <a href="/order">Egusi Soup</a>
          </li>
          <li>
            <a href="/order">Pounded Yam</a>
          </li>
          <li>
            <a href="/order">Veg Swallows</a>
          </li>
          <li>
            <a href="/order">Oat Swallow</a>
          </li>
          <li>
            <a href="/order">Jollof Rice</a>
          </li>
          <li>
            <a href="/order">Fried Rice</a>
          </li>

          <li>
            <a href="/order">Fried Plantain</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
