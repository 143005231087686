import React from "react";
import "./Home.css";
import { Carousel } from "./Carousel";
import { Hero } from "./Hero";

export function Home() {

  const slides = [
    `${process.env.PUBLIC_URL}/slide-1.png`,
    `${process.env.PUBLIC_URL}/slide-12.png`,
    `${process.env.PUBLIC_URL}/slide-17.png`,
    `${process.env.PUBLIC_URL}/slide-14.png`,
    `${process.env.PUBLIC_URL}/slide-5.png`,
    `${process.env.PUBLIC_URL}/slide-16.png`,
    `${process.env.PUBLIC_URL}/slide-7.png`,
    `${process.env.PUBLIC_URL}/slide-8.png`,
    `${process.env.PUBLIC_URL}/slide-10.png`,
    `${process.env.PUBLIC_URL}/slide-11.png`,
    `${process.env.PUBLIC_URL}/slide-2.png`,
    `${process.env.PUBLIC_URL}/slide-13.png`,
    `${process.env.PUBLIC_URL}/slide-4.png`,
    `${process.env.PUBLIC_URL}/slide-15.png`,
    `${process.env.PUBLIC_URL}/slide-6.png`,
    `${process.env.PUBLIC_URL}/slide-3.png`, 
  ];

  return (
    <div className="home">
      {/* <Navigation /> */}
      <Hero />
      {/* <h1>Indulge in our special menu</h1> */}
      <Carousel slides={slides} />
      

      <div className="home-content">
        <div className= "contents">
          <h1 className="home-title">Sobo Enclave</h1>
          <p className="corousel-subtitle">Authentic Nigerian Cuisine</p>
          {/* <button className="order-online-btn">
            <a href="/order">Order Online</a>
          </button> */}
        </div>
      </div>
    </div>
  );
}