import React from "react";
import "./Menu.css";

export function Menu() {
  const handleOrderButtonClick = () => {
    alert("Order from WhatsApp: +447548830808 / +440742353188!");
  };

  const menuItems = [
    {
      name: "Banga Soup",
      image: "slide-1.png",
      price:
        "£17.99 - Sides: Starch, Pounded Yam, Pitta Bread vegetable swallows (optional)",
      description:
        "Niger Delta's banga soup, a palm nut symphony, teams up with starch or pounded yam, igniting taste buds with flavor fireworks. And the ultimate secret weapon? Pitta bread – the crown jewel of this epic feast! 🌴🍲🥖🎉",
      allergies: "Contains nuts, seeds, and shellfish",
    },
    {
      name: "Egusi Soup",
      image: "slide-7.png",
      price:
        "£15.99 - Sides: Pounded Yam, Eba, Pitta Bread vegetable swallows (optional)",
      description:
        "Sobo Enclave's knockout Egusi soup lets you pick your meaty champ! Yoruba tribe's treasure, made from melon seeds, rules the taste game. Crown it with pounded yam or eba for a lip-smacking victory feast! 🥘👑🥊",
      allergies: "Contains nuts, seeds, and shellfish",
    },
    {
      name: "Suya",
      image: "menu-12.jpg",
      price:
        "£11.99 - Sides: Suya-Wrap with pitta bread and all the delicious goodness of suya",
      description:
        "Sobo Enclave soft and tender suya! This recipe is a precious gem, handed down by a renowned Northern chef, tracing their roots to the vibrant Hausa tribe of Nigeria. Your taste buds are in for a tantalizing treat! 🔥🍢👨‍🍳",
      allergies: "Contains nuts, seeds, and shellfish",
    },

    {
      name: "Chicken Suya",
      image: "chickensuya.jpeg",
      price: "£11.99 - Sides: Plantain, Rice, Pitta Bread. Booster options available ",
      description:
        "Our signature Chicken Suya. Need a taste of the North blended with the South? This combo will take you on a culinary adventure, with a tantalizing taste of the North of Nigeria. 🍚🔥🍗🍌",
      allergies: "Contain nuts, seeds, and gluten",
    },

    {
      name: "Jollof Rice",
      image: "slide-10.png",
      price:
        "£7.99 - Sides: Sweet Chilli Chicken, Plantain, Chicken and Salad. Booster options: ",
      description:
        "Our Smokey Jollof rice! This well guarded recipe is a treasure from the South of Nigeria that predates the famous 'Jollof wars.' Perfectly paired with Sweet Chilli Chicken or beef stew, this is a feast fit for legends! 🍚🔥🍗🍌",
      allergies: "May contain nuts, seeds, and shellfish ",
    },
    {
      name: "Fried Rice",
      image: "menu-10.jpg",
      price:
        "£7.99 - Sides: Plantain, Chicken and Salad. Sweet Chilli Chicken, Booster options:",
      description:
        "Our sensational fried rice, a peace treaty in the epic 'jollof wars' saga! Choose your sidekicks and embark on a culinary adventure. Crowned with fried plantains and chicken, it's a tasty truce worth celebrating! 🍚✌️🍗🍌",
      allergies: "May contain nuts, seeds, and shellfish ",
    },
    {
      name: "Coconut Chicken",
      image: "menu-04.jpg",
      price:
        "£7.99 - Sides: Plantain, Rice. Pitta Bread. Booster options available ",
      description:
        "A very closely guarded recipe passed down through generations within the Okpara tribe. It's the embodiment of a hidden culinary heritage, ready to enchant your taste buds with its mystique and flavor. 🤫🌴🥥🍲",
      allergies: "May contain nuts, seeds, and shellfish ",
    },

    {
      name: "Coconut Chicken",
      image: "menu-03.jpg",
      price:
        "£7.99 - Sides: Plantain, Rice. Pitta Bread. Booster options available ",
      description:
        "A very closely guarded recipe passed down through generations within the Okpara tribe. It's the embodiment of a hidden culinary heritage, ready to enchant your taste buds with its mystique and flavor. 🤫🌴🥥🍲",
      allergies: "May contain nuts, seeds, and shellfish ",
    },

    {
      name: "Sweet Chilli Chicken",
      image: "menu-08.jpg",
      price: "£7.99 - Sides: Plantain, Rice. Pitta Bread. Booster options available ",
      description:
        "Our signature Sweet Chilli Chicken is a feast fit for legends! This treasure from the South of Nigeria predates the famous 'Jollof wars.' Perfectly paired with our Smokey Jollof rice! 🍚🔥🍗🍌",
      allergies: "May contain nuts, seeds, and shellfish ",
    },

    {
      name: "Suya and Suya Wrap",
      image: "menu-05.jpg",
      price: "£10.99 - Sides: Plantain, Rice, Pitta Bread. Booster options available ",
      description:
        "Our signature Suya Wrap. A journey to a new world of taste! This combo will take you on a culinary adventure, with a tantalizing taste of the North of Nigeria. 🍚🔥🍗🍌",
      allergies: "Contain nuts, seeds, and gluten and shellfish",
    },
  ];

  return (
    <div className="menu">
      <div className="menu-contents">
        {menuItems.map((item, index) => (
          <div className="menu-contents__wrapper" key={index}>
            <h3>{item.name}</h3>
            <div className="menu-contents__image">
              <img
                src={`${process.env.PUBLIC_URL}/${item.image}`}
                alt={item.name}
              />
              <p>{item.description} </p>
              <button className="order-btn" onClick={handleOrderButtonClick}>
                Add to Feast
              </button>
              <h6>{item.price}</h6>
              <h5> Allergy Advice: {item.allergies}</h5>
            </div>
            {/* <h6>{item.price}</h6> */}
          </div>
        ))}
      </div>
    </div>
  );
}
