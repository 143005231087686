import React from "react";
import "./About.css";

export function About() {
  return (
    <div className="about-container">
      <div className="about-contents">
        <img src="/SoboEnclaveLogo.PNG" alt="Sobo Logo" width={"200px"} />
        <h1>About Us</h1>
        <h3>Our Vision</h3>
        <p>
          At Sobo Enclave, we are on a mission to showcase the beauty and depth
          of Nigerian cuisine to the world. Our vision is to promote the rich
          cultural heritage embedded in Nigerian food, using locally sourced
          ingredients and traditional cooking techniques to offer an authentic
          and unforgettable dining experience.
        </p>

        <h3>Our Mission</h3>
        <p>
          Our mission is to celebrate the distinct flavors and culinary
          traditions of Nigeria, providing a platform where food enthusiasts can
          relish the true essence of Nigerian dishes. We are committed to
          sourcing the finest local ingredients, maintaining high-quality
          standards, and offering exceptional service that embodies the warmth
          and hospitality of Nigeria.
        </p>

        <h3>The Sobo Enclave Experience</h3>
        <p>
          <strong>Nigerian Culinary Diversity</strong>: Sobo Enclave takes pride
          in presenting an extensive menu showcasing the diverse flavors of
          Nigerian cuisine. From the aromatic jollof rice to the flavorful suya,
          from the spicy egusi soup to the satisfying pounded yam, our menu is a
          celebration of Nigeria's gastronomic heritage.
          <br />
          <br />
          <strong>Authenticity and Flavor</strong>: We strive to bring you the
          authentic taste of Nigeria with every dish. Our experienced chefs
          infuse passion and expertise into their cooking, ensuring that each
          meal bursts with the true flavors and spices that make Nigerian
          cuisine extraordinary.
          <br />
          <br />
          <strong>Customer Satisfaction</strong>: Your satisfaction is our
          priority. We value your feedback and continuously work to enhance our
          offerings and services. Our attentive customer support team is always
          available to assist you, making your ordering experience seamless and
          enjoyable.
          <br />
          <br />
          <strong>Effortless Ordering</strong>: Ordering from Sobo Enclave is
          effortless and user-friendly. Our intuitive website and mobile app
          make it easy to explore our menu, place your order, and eagerly await
          the arrival of your delightful Nigerian meal.
          <br />
          <br />
          <strong>Timely Delivery</strong>: We understand the importance of
          timely delivery. Our dedicated delivery network ensures your order is
          delivered promptly, allowing you to enjoy the flavors of Nigeria at
          their best.
          <br />
          <br />
          Experience the richness and authenticity of Nigerian cuisine at Sobo
          Enclave, including CNN's acclaimed Banga Soup. Join us on a
          gastronomic adventure through the flavors and aromas that define the
          heart and soul of Nigerian cooking. Your journey to a world of
          delicious Nigerian cuisine starts here!
        </p>
        <h3>Allergen Advice</h3>
        <p>
          We take allergen advice seriously and are committed to providing
          accurate information about the ingredients used in our dishes. If you
          have any allergies or dietary requirements, please let us know and we
          will do our best to accommodate your needs.
        </p>
      </div>
    </div>
  );
}
