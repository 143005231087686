import React from 'react';
import "./Special.css";

export function Special () {
    const handleClick = () => {
        alert("Sorry, this offer is no longer available.Check back soon!");
    };

    return (
        <div className="special-container">
            <h1>Special</h1>
            <p>This is a special offer just for you!</p>
            <div className="card-container">
                <div className="card">
                    <img src={process.env.PUBLIC_URL + '/menu-07.jpg'} alt="Product 1" />
                    <h2>Sweet  Chicken</h2>
                    <p>Discounted Price: £10</p>
                    <button onClick={handleClick}>Add to Feast</button>
                </div>
                <div className="card">
                    <img src={process.env.PUBLIC_URL + '/menu-05.jpg'} alt="Product 2" />
                    <h2>Suya Wrap</h2>
                    <p>Discounted Price: £10</p>
                    <button onClick={handleClick}>Add to Feast</button>
                </div>
                <div className="card">
                    <img src={process.env.PUBLIC_URL + '/menu-02.jpg'} alt="Product 3" />
                    <h2>Coconut Chicken </h2>
                    <p>Discounted Price: £10 </p>
                    <button onClick={handleClick}>Add to Feast</button>
                </div>
            </div>
            {/* <button onClick={handleClick}>Claim Offer</button> */}
        </div>
    );
}   